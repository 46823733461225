<template>
    <div class="home_container">
        <div class="detail_info">
            <div class="detail_info_top">
                <swiper :options="detailOption" class="detail_swiper">
                    <swiper-slide v-if="imgs.length === 0">
                        <img :src="this.data.mainPic" alt="">
                    </swiper-slide>
                    <swiper-slide :key="item.id" v-for="item in imgs">
                        <img :src="item" alt="">
                    </swiper-slide>
                    <div class="swiper-pagination" slot="pagination"></div>
                </swiper>
                <div class="detail_num">
                    <p class="detail_price">
                        <i>￥&nbsp;<font>{{ this.data.actualPrice }}</font></i>
                        <span class="old_price">原价</span>
                        <span class="old_price_money">￥{{ this.data.originalPrice }}</span>
                    </p>
                    <p class="detail_sellnum">
                        <span class="gray">已售</span>
                        <span class="red">{{ this.data.monthSales }}</span>
                        <span class="gray">件</span>
                    </p>
                </div>
            </div>
            <div class="detail_title">
                <img src="../assets/tmicon.png" alt="" v-if="this.data.shopType === 1">
                <img src="../assets/tbicon.png" alt="" v-if="this.data.shopType === 0">
                {{ this.data.title }}
            </div>
            <div class="favourite" @click="saveFavourite" v-if="!iscollect">收藏</div>
            <div class="collect" @click="cancelFavourite" v-if="iscollect">取消</div>
        </div>
        <div class="award">
            <span class="award_num">预估特权佣金：{{ Math.floor(this.data.actualPrice * this.data.commissionRate / 2) / 100 }}元</span>
            <span class="award_get" @click="getCode" v-if="!userInfo.relationId">立即升特权？</span>
        </div>
        <div class="d_coupon_container">
            <div class="d_coupon flex-row" @click="couponCopy">
                <div class="coupon_left flex-row">
                    <div class="coupon_num flex-row">
                        ￥<span>{{ this.data.couponPrice }}</span>
                    </div>
                    <div class="coupon_date flex-column">
                        <span>优惠券使用期限</span>
                        <span>开始 {{ this.data.couponStartTime }}</span>
                        <span>结束 {{ this.data.couponEndTime }}</span>
                    </div>
                </div>
                <div class="coupon_right">领券购买</div>
            </div>
        </div>
        <div class="recommend_container" v-if="this.data.desc">
            <div>
                <div class="recommend_title flex-row">
                    <div class="recommend_icon flex-row">
                        <img src="../assets/recommend_icon.png" alt="">达人推荐
                    </div>
                </div>
                <div class="recommend_content">{{ this.data.desc }}</div>
            </div>
        </div>
        <div class="shop_container">
            <div @click="shopCopy">
                <div class="shop_title">
                    <img :src="this.data.shopLogo" alt="" v-if="data.shopLogo">
                    <img src="../assets/default.png" alt="" v-if="!data.shopLogo">
                    <div class="shop_info">
                        <h3 class="title">{{ this.data.shopName }}</h3>
                        <div class="platform">
                            <img src="../assets/tm.png" alt="" v-if="this.data.shopType === 1">
                        </div>
                        <p class="enter_shop">进入店铺
                            <img src="../assets/right.png" alt="">
                        </p>
                    </div>
                </div>
                <div class="shop_tab row-s">
                    <div class="tab">宝贝描述：{{ this.data.dsrScore }}</div>
                    <div class="tab">卖家服务：{{ this.data.serviceScore }}</div>
                    <div class="tab">物流服务：{{ this.data.shipScore }}</div>
                </div>
            </div>
        </div>
        <div class="evaluate_container">
            <div class="evaluate_key">
                <div :key="item.id" v-for="item in comm.tagList">{{ item.tag }}（{{ item.count }}）</div>
            </div>
        </div>
        <div class="goods_detail">
            <div class="show_detail" :class="isallheight?'all_height':'limit_height'">
                <div class="detail_list">
                    <img :src="item.img" alt="" :key="item.id" v-for="item in detailPics">
                </div>
            </div>
        </div>
        <div class="change_height" v-if="this.data.detailPics">
            <div @click="changeHeight">
                <span class="change_button" :class="isallheight?'noblocked':'blocked'">
                    <img src="../assets/down.png" alt="">
                    点击查看全部详情
                    <img src="../assets/down.png" alt="">
                </span>
                <span class="change_button" :class="isallheight?'blocked':'noblocked'">
                    <img src="../assets/down.png" alt="">
                    收起
                    <img src="../assets/down.png" alt="">
                </span>
            </div>
        </div>
        <div class="guess_like" v-if="data.id !== -1 && showSim.length > 0">
            <div class="guess_title">
                <span>猜你喜欢</span>
                <span class="guess_refresh">
                    <img src="../assets/refresh.png" alt="" :class="isrotate?'rotate_img':''" @click="changeRotate">
                </span>
            </div>
            <div class="guess_list">
                <ul>
                    <li :key="item.id" v-for="item in showSim">
                        <div @click="reLoad(item.id, item.goodsId)">
                            <img :src="item.mainPic" alt="">
                            <h3>{{ item.title }}</h3>
                            <div class="guess_price">
                                <span class="price_left">
                                    <i>券后 ￥</i>{{ item.actualPrice }}
                                </span>
                                <span class="price_right">月销{{ item.monthSales }}</span>
                            </div>
                            <div class="guess_coupon">
                                <span>{{ item.couponPrice }}元券</span>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <div class="bottom_distance"></div>
        <div class="go_back">
            <img src="../assets/return.png" alt="" @click="goBack">
        </div>
        <div class="return_home">
            <router-link to="/home">
            <img src="../assets/rehome.png" alt="">
            </router-link>
        </div>
        <div class="make_pic">
            <img src="../assets/pic.png" alt="" @click="makePic">
        </div>
        <div class="find_same">
            <img class="btndh" src="../assets/same.png" alt="" @click="changeDialog">
        </div>
        <div class="bottom_menu">
            <div class="show_home">
                <router-link to="/home">
                    <img src="../assets/show_home.png" alt="">&nbsp;首页
                </router-link>
            </div>
            <div class="show_share" @click="makePic">
                <img class="btndh" src="../assets/show_share.png" alt="">&nbsp;分享
            </div>
            <div class="show_open">
                <a href="javascript:return false;">
                    手淘APP打开
                </a>
            </div>
            <div class="show_get" @click="couponCopy">
                <div class="get_button">点击购买</div>
            </div>
        </div>
        <div class="back_top" :class="isblock?'show_back_top':'hide_back_top'" @click="backTop">
            <span>顶部</span>
        </div>
        <div class="loading_container" :class="isloading?'blocked':'noblocked'">
            <div class="loading">
                <img src="../assets/loading2.gif" alt="">
                <br>
                加载中，请稍候
                <br>
            </div>
        </div>
        <div class="cover" :class="iscover?'blocked':'noblocked'"></div>
        <div class="show_pic .picin" :class="ispic?'blocked':'noblocked'">
            <div class="pic">
                <div class="pic_outside">
                    <div class="pic_inside">
                        <img :src="this.imgUrl" alt="">
                    </div>
                </div>
            </div>
            <span class="pic_close">
                <div class="close_btn close_icon" @click="closePic"></div>
            </span>
            <div class=""></div>
            <!-- <div class="pic_btn">
                <div class="copy_btn">复制文案</div>
            </div> -->
            <div class="pic_tip">长按保存，分享给好友，赚取佣金</div>
        </div>
        <div :class="istoast?'blocked':'noblocked'">
            <div class="weui-mask_transparent"></div>
            <div class="weui-toast weui-toast_text-more">
                <i class="weui-icon-success-no-circle weui-icon_toast"></i>
                <p class="weui-toast__content">{{ toastContent1 }}</p>
                <p class="weui-toast__content">{{ toastContent2 }}</p>
            </div>
        </div>
        <div :class="iswarntoast?'blocked':'noblocked'">
            <div class="weui-mask_transparent"></div>
            <div class="weui-toast">
                <i class="weui-icon-warn weui-icon_toast"></i>
                <p class="weui-toast__content">{{ this.warncontent }}</p>
            </div>
        </div>
        <div :class="isdialog?'blocked':'noblocked'">
            <div class="weui-mask"></div>
            <div class="weui-dialog">
                <div class="weui-dialog__bd">
                    <div class="icon-box">
                        <i class="weui-icon-info weui-icon_msg"></i>
                        <div class="icon-box-info">是否立即前往查询同款商品？</div>
                    </div>
                </div>
                <div class="weui-dialog__ft">
                    <a href="javascript:;" class="weui-dialog__btn weui-dialog__btn_default" @click="changeDialog">取消</a>
                    <router-link :to="{ name: 'search', query: { title: this.data.dtitle } }" class="weui-dialog__btn weui-dialog__btn_primary">确认</router-link>
                </div>
            </div>
        </div>
        <div :class="iscopydialog?'blocked':'noblocked'">
            <div class="weui-mask"></div>
            <div class="weui-dialog">
                <div class="weui-dialog__bd">
                    <div class="icon-box">
                        <i class="weui-icon-success weui-icon_msg"></i>
                        <div class="icon-box-info">口令<p>{{ this.tpwdlink }}</p> </div>
                    </div>
                </div>
                <div class="weui-dialog__ft">
                    <a href="javascript:;" class="weui-dialog__btn weui-dialog__btn_default" @click="changeCopyDialog">取消</a>
                    <a class="weui-dialog__btn weui-dialog__btn_primary" @click="doCopy">复制口令</a>
                </div>
            </div>
        </div>
        <div :class="issharedialog?'blocked':'noblocked'">
            <div class="weui-mask"></div>
            <div class="weui-dialog">
                <div class="weui-dialog__bd">
                    此为分享链，请直接领券购买，离开本业分享收益将失效！
                </div>
                <div class="weui-dialog__ft">
                    <a href="javascript:;" class="weui-dialog__btn weui-dialog__btn_primary" @click="changeShareDialog">知道了</a>
                </div>
            </div>
        </div>
        <div class="qc_container" v-if="this.isimg" ref="qcRef">
            <img :src="this.data.mainPic" alt="" class="qc_img">
            <div class="qc_info">
                <div class="qc_left">
                    <div class="qc_title">{{ this.data.title }}</div>
                    <div class="qc_price">
                        <i>￥&nbsp;<font>{{ this.data.actualPrice }}</font></i>
                        <span class="old_price">原价</span>
                        <span class="old_price_money">￥{{ this.data.originalPrice }}</span>
                    </div>
                    <div class="qc_sell">
                        <span class="gray">已售</span>
                        <span class="red">{{ this.data.monthSales }}</span>
                        <span class="gray">件</span>
                    </div>
                </div>
                <div class="qc_right">
                    <vue-qr :text="qrData.url" :margin="0" colorDark="black" colorLight="#fff" :logoSrc="qrData.icon" :logoScale="0.2" :logoCornerRadius="1" :size="200" class="qc" />
                    <div class="qc_tip">
                        <div>长按扫码</div>
                        <div>领券购买</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="weui-toptips weui-toptips_warn" :class="isTopwarn?'opacitied':'noopacitied'">{{ this.topWarn }}</div>
    </div>
</template>

<script>
import html2canvas from 'html2canvas'
export default {
    mounted () {
        window.addEventListener('scroll', this.watchScroll)
    },
    destroyed () {
        window.removeEventListener('scroll', this.watchScroll)
    },
    created () {
        window.scrollTo(0, 0)
        var reg = /(((https|http|ftp|rtsp|mms)?:|)\/\/)[^\s]+?(jpg|png)/g
        var userCookie = this.$cookies.get('userCookie')
        if (userCookie && typeof (userCookie) === 'object') {
            this.islogin = true
            this.userInfo = userCookie
        }
        if (this.$route.query.relationid) {
            this.userInfo.relationId = this.$route.query.relationid
            this.issharedialog = true
        }
        this.$nextTick(async function () {
            const { data: isGoods } = await this.$http.post('isGoods', {
                iid: this.$route.query.id,
                goodsId: this.$route.query.goodsId
            })
            if (isGoods.status === 200) {
                if (isGoods.data) {
                    this.data = {
                        id: isGoods.data.iid,
                        goodsId: isGoods.data.goodsid,
                        mainPic: isGoods.data.img,
                        imgs: isGoods.data.imgs,
                        title: isGoods.data.title,
                        originalPrice: isGoods.data.oldprice,
                        actualPrice: isGoods.data.newprice,
                        couponPrice: isGoods.data.quannum,
                        shopType: isGoods.data.shoptype,
                        commissionRate: isGoods.data.rate,
                        monthSales: isGoods.data.sellnum,
                        desc: isGoods.data.recomend,
                        couponStartTime: isGoods.data.starttime,
                        couponEndTime: isGoods.data.endtime,
                        shopLogo: isGoods.data.shoplogo,
                        shopName: isGoods.data.shopname,
                        dsrScore: isGoods.data.descscore,
                        serviceScore: isGoods.data.servicescore,
                        shipScore: isGoods.data.shipscore,
                        detailPics: isGoods.data.detailpics,
                        sellerId: isGoods.data.sellerid
                    }
                    if (this.data.detailPics) {
                        this.detailPics = JSON.parse(this.data.detailPics)
                    }
                    if (this.data.imgs) {
                        this.imgs = this.data.imgs.match(reg)
                    }
                } else {
                    const { data: res } = await this.$http.post('goods/detail', {
                        id: this.$route.query.id,
                        goodsid: this.$route.query.goodsId
                    })
                    if (res.msg === '成功') {
                        this.data = res.data
                        if (this.data.detailPics) {
                            this.detailPics = JSON.parse(this.data.detailPics)
                        }
                        if (this.data.imgs) {
                            this.imgs = this.data.imgs.match(reg)
                        }
                        this.goods = {
                            iid: this.data.id,
                            goodsId: this.data.goodsId,
                            img: this.data.mainPic,
                            imgs: this.data.imgs,
                            title: this.data.title,
                            oldPrice: this.data.originalPrice,
                            newPrice: this.data.actualPrice,
                            quanNum: this.data.couponPrice,
                            shopType: this.data.shopType,
                            rate: this.data.commissionRate,
                            sellNum: this.data.monthSales,
                            recomend: this.data.desc,
                            startTime: this.data.couponStartTime,
                            endTime: this.data.couponEndTime,
                            shopLogo: this.data.shopLogo,
                            shopName: this.data.shopName,
                            descScore: this.data.dsrScore,
                            serviceScore: this.data.serviceScore,
                            shipScore: this.data.shipScore,
                            detailPics: this.data.detailPics,
                            sellerId: this.data.sellerId
                        }
                        this.$http.post('insertGoods', this.goods)
                    } else {
                        this.isloading = false
                        this.iswarntoast = true
                        this.warncontent = '无对应商品'
                        setTimeout(() => {
                                this.iswarntoast = false
                                this.$router.go(-1)
                            }, 1500)
                    }
                }
            }
            if (this.islogin) {
                this.collect = {
                    userId: this.userInfo.id,
                    iid: this.data.id,
                    goodsId: this.data.goodsId
                }
                const { data: iscollect } = await this.$http.post('isCollect', this.collect)
                if (iscollect.status === 200) {
                    this.iscollect = iscollect.data
                } else if (iscollect.status === 557) {
                    this.topWarn = iscollect.msg
                    this.isTopwarn = true
                    setTimeout(() => {
                        this.isTopwarn = false
                        this.topWarn = ''
                    }, 2000)
                }
            }
            // const { data: comm } = await this.$http.post('goods/comment', {
            //     id: this.$route.query.id,
            //     goodsid: this.$route.query.goodsId
            // })
            // if (comm.msg === '成功') {
            //     this.comm = comm.data
            // }
            this.isloading = false
        })
    },
    activated () {
        this.iskeep = true
        var userCookie = this.$cookies.get('userCookie')
        if (userCookie && typeof (userCookie) === 'object') {
            this.islogin = true
            this.userInfo = userCookie
        } else {
            this.islogin = false
            this.userInfo = {
                relationId: null
            }
        }
    },
    deactivated () {
        this.iskeep = false
    },
    watch: {
        $route (to, from) {
            window.scrollTo(0, 0)
            var reg = /((https|http|ftp|rtsp|mms)?:\/\/)[^\s]+?(jpg|png)/g
            this.showSim = []
            this.couponLink = ''
            this.shopLink = ''
            this.isGetSim = false
            this.isallheight = false
            this.isdialog = false
            this.isloading = true
            this.iscollect = false
            if (to.fullPath !== from.fullPath && to.name === 'detail') {
                this.$nextTick(async function () {
                    const { data: isGoods } = await this.$http.post('isGoods', {
                        iid: this.$route.query.id,
                        goodsId: this.$route.query.goodsId
                    })
                    if (isGoods.status === 200) {
                        if (isGoods.data) {
                            this.data = {
                                id: isGoods.data.id,
                                iid: isGoods.data.iid,
                                goodsId: isGoods.data.goodsid,
                                mainPic: isGoods.data.img,
                                imgs: isGoods.data.imgs,
                                title: isGoods.data.title,
                                originalPrice: isGoods.data.oldprice,
                                actualPrice: isGoods.data.newprice,
                                couponPrice: isGoods.data.quannum,
                                shopType: isGoods.data.shoptype,
                                commissionRate: isGoods.data.rate,
                                monthSales: isGoods.data.sellnum,
                                desc: isGoods.data.recomend,
                                couponStartTime: isGoods.data.starttime,
                                couponEndTime: isGoods.data.endtime,
                                shopLogo: isGoods.data.shoplogo,
                                shopName: isGoods.data.shopname,
                                dsrScore: isGoods.data.descscore,
                                serviceScore: isGoods.data.servicescore,
                                shipScore: isGoods.data.shipscore,
                                detailPics: isGoods.data.detailpics,
                                sellerId: isGoods.data.sellerid
                            }
                            if (this.data.detailPics) {
                                this.detailPics = JSON.parse(this.data.detailPics)
                            }
                            if (this.data.imgs) {
                                this.imgs = this.data.imgs.match(reg)
                            }
                        } else {
                            const { data: res } = await this.$http.post('goods/detail', {
                                id: this.$route.query.id,
                                goodsid: this.$route.query.goodsId
                            })
                            if (res.msg === '成功') {
                                this.data = res.data
                                if (this.data.detailPics) {
                                    this.detailPics = JSON.parse(this.data.detailPics)
                                } else {
                                    this.detailPics = []
                                }
                                if (this.data.imgs) {
                                    this.imgs = this.data.imgs.match(reg)
                                } else {
                                    this.imgs = []
                                }
                                this.goods = {
                                    iid: this.data.id,
                                    goodsId: this.data.goodsId,
                                    img: this.data.mainPic,
                                    imgs: this.data.imgs,
                                    title: this.data.title,
                                    oldPrice: this.data.originalPrice,
                                    newPrice: this.data.actualPrice,
                                    quanNum: this.data.couponPrice,
                                    shopType: this.data.shopType,
                                    rate: this.data.commissionRate,
                                    sellNum: this.data.monthSales,
                                    recomend: this.data.desc,
                                    startTime: this.data.couponStartTime,
                                    endTime: this.data.couponEndTime,
                                    shopLogo: this.data.shopLogo,
                                    shopName: this.data.shopName,
                                    descScore: this.data.dsrScore,
                                    serviceScore: this.data.serviceScore,
                                    shipScore: this.data.shipScore,
                                    detailPics: this.data.detailPics,
                                    sellerId: this.data.sellerId
                                }
                                this.$http.post('insertGoods', this.goods)
                            }
                        }
                    // const { data: comm } = await this.$http.post('goods/comment', {
                    //     id: this.$route.query.id,
                    //     goodsid: this.$route.query.goodsId
                    // })
                    // if (comm.msg === '成功') {
                    //     this.comm = comm.data
                    // }
                        }
                    if (this.islogin) {
                        this.collect = {
                            userId: this.userInfo.id,
                            iid: this.data.id,
                            goodsId: this.data.goodsId
                        }
                        const { data: iscollect } = await this.$http.post('isCollect', this.collect)
                        if (iscollect.status === 200) {
                            this.iscollect = iscollect.data
                        } else if (iscollect.status === 557) {
                            this.topWarn = iscollect.msg
                            this.isTopwarn = true
                            setTimeout(() => {
                                this.isTopwarn = false
                                this.topWarn = ''
                            }, 2000)
                        }
                    }
                    this.isloading = false
                })
            }
        }
    },
    data () {
        return {
            tpwdlink: '',
            iscopydialog: false,
            isTopwarn: false,
            topWarn: '',
            iskeep: true,
            iscover: false,
            getcodeurl: 'https://oauth.taobao.com/authorize?response_type=code&client_id=32396137&redirect_uri=https://www.8y8w.cn/api/discount_gift_api/auth/&view=wap',
            islogin: false,
            userInfo: {
                relationId: null
            },
            isimg: false,
            imgUrl: '',
            qrData: {
                url: window.location.href
            },
            selectsim: 0,
            isGetSim: false,
            showSim: [],
            sim: [],
            imgs: [],
            detailPics: [],
            data: {},
            comm: {},
            isblock: false,
            isallheight: false,
            isrotate: false,
            isloading: true,
            ispic: false,
            istoast: false,
            iswarntoast: false,
            warncontent: '请先登录',
            issharedialog: false,
            isdialog: false,
            istoptipsuccess: true,
            // swiper
            detailOption: {
                pagination: '.swiper-pagination',
                observer: true,
                observeParents: true,
                paginationType: 'bullets'
            },
            // toast
            toastContent1: '',
            toastContent2: '',
            // copydata
            couponLink: '',
            shopLink: '',
            // scrollTop
            scrollTop: null,
            collect: {},
            iscollect: false
        }
    },
    methods: {
        watchScroll () {
            const that = this
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
            const scrollHeight = document.body.scrollHeight
            const clientHeight = document.body.clientHeight
            const scrollBottom = scrollHeight - clientHeight - scrollTop
            if (scrollBottom < 100 && !this.isGetSim && this.iskeep) {
                this.isGetSim = true
                this.$nextTick(async function () {
                    const { data: sim } = await this.$http.post('goods/similer', {
                        id: this.$route.query.id
                    })
                    if (sim.msg === '成功') {
                        this.sim = sim.data
                        this.calcSim()
                    }
                })
            }
            that.scrollTop = scrollTop
            if (that.scrollTop > 100) {
                that.isblock = true
            } else {
                that.isblock = false
            }
        },
        backTop () {
            const that = this
            const timer = setInterval(() => {
                const ispeed = Math.floor(-that.scrollTop / 5)
                document.documentElement.scrollTop = document.body.scrollTop = that.scrollTop + ispeed
                if (that.scrollTop === 0) {
                    clearInterval(timer)
                }
            }, 16)
        },
        goBack () {
            this.$router.go(-1)
        },
        makePic () {
            if (!this.imgUrl) {
                document.documentElement.scrollTop = document.body.scrollTop = 0
                if (this.userInfo.relationId !== null) {
                    this.qrData.url = this.qrData.url + '&relationid=' + this.userInfo.relationId
                }
                this.isimg = true
                setTimeout(() => {
                    this.copyPic()
                }, 1)
                this.ispic = true
                this.iscover = true
            } else {
                this.ispic = true
                this.iscover = true
            }
        },
        closePic () {
            this.ispic = false
            this.iscover = false
        },
        couponCopy () {
            this.toastContent1 = '领券成功'
            this.toastContent2 = '打开淘宝app立即使用'
            if (this.couponLink) {
                this.iscopydialog = true
                this.tpwdlink = this.couponLink
            } else {
                this.$nextTick(async function () {
                    const { data: priv } = await this.$http.post('goods/privilege', {
                        goodsid: this.data.goodsId,
                        couponid: '',
                        pid: this.userInfo.relationId === null ? '0' : '1',
                        channelid: this.userInfo.relationId === null ? '' : this.userInfo.relationId,
                        rebatetype: '',
                        specialid: '',
                        externalid: '',
                        xid: ''
                    })
                    if (priv.status === 200) {
                        this.tpwdlink = priv.data.tpwd
                        this.couponLink = priv.data.tpwd
                        this.iscopydialog = true
                    }
                })
            }
        },
        shopCopy () {
            this.toastContent1 = '获取店铺链接'
            this.toastContent2 = '打开淘宝app立即前往'
            if (this.shopLink) {
                this.iscopydialog = true
                this.tpwdlink = this.shopLink
            } else {
                this.$nextTick(async function () {
                    const { data: shop } = await this.$http.post('goods/shop', {
                        sellerid: this.data.sellerId,
                        pid: this.userInfo.relationId === null ? '0' : '1',
                        relationid: this.userInfo.relationId === null ? '' : this.userInfo.relationId,
                        shopname: this.data.shopName.length >= 5 ? this.data.shopName : this.data.shopName + '海淘优惠shop'
                    })
                    if (shop.msg === 'OK') {
                        this.tpwdlink = shop.data.tpwd
                        this.shopLink = shop.data.tpwd
                        this.iscopydialog = true
                    }
                })
            }
        },
        doCopy () {
            this.iscopydialog = false
            this.$copyText(this.tpwdlink).then(
                this.istoast = true
            )
            setTimeout(() => {
                this.istoast = false
            }, 1500)
        },
        changeDialog () {
            this.isdialog = !this.isdialog
        },
        changeShareDialog () {
            this.issharedialog = !this.issharedialog
        },
        changeCopyDialog () {
            this.iscopydialog = !this.iscopydialog
        },
        changeRotate () {
            this.isrotate = true
            setTimeout(() => {
                this.isrotate = false
            }, 1000)
            this.calcSim()
        },
        changeHeight () {
            this.isallheight = !this.isallheight
        },
        calcSim () {
            this.showSim = []
            if (this.sim.length >= 6) {
                for (var i = 0; i < 6; i++) {
                    if (this.selectsim >= this.sim.length) {
                        this.selectsim = 0
                    }
                    this.showSim.push(this.sim[this.selectsim])
                    this.selectsim += 1
                }
            } else {
                this.showSim = this.sim
            }
        },
        reLoad (id, goodsId) {
            this.$router.push({
                name: 'detail',
                query: {
                    id: id,
                    goodsId: goodsId
                }
            })
        },
        copyPic () {
            if (!this.imgUrl) {
                this.$nextTick(() => {
                    html2canvas(this.$refs.qcRef, {
                        backgroundColor: null,
                        useCORS: true,
                        logging: true,
                        scale: window.devicePixelRatio
                    }).then(canvas => {
                        this.imgUrl = canvas.toDataURL('image/png')
                        this.isimg = false
                    })
                })
            }
        },
        saveFavourite () {
            if (!this.islogin) {
                this.noLogin()
            } else {
                this.$nextTick(async function () {
                    const { data: res } = await this.$http.post('collect', this.collect)
                    if (res.status === 200) {
                        this.iscollect = true
                    } else if (res.status === 557) {
                        this.topWarn = res.msg
                        this.isTopwarn = true
                        setTimeout(() => {
                            this.isTopwarn = false
                            this.topWarn = ''
                        }, 2000)
                    }
                })
            }
        },
        cancelFavourite () {
            this.$nextTick(async function () {
                const { data: res } = await this.$http.post('cancelCollect', this.collect)
                if (res.status === 200) {
                    this.iscollect = false
                } else if (res.status === 557) {
                    this.topWarn = res.msg
                    this.isTopwarn = true
                    setTimeout(() => {
                        this.isTopwarn = false
                        this.topWarn = ''
                    }, 2000)
                }
            })
        },
        getCode () {
            if (!this.islogin) {
                this.noLogin()
            } else {
                this.$router.push('/myinfo')
            }
        },
        noLogin () {
            this.iswarntoast = true
            this.warncontent = '请先登录'
            setTimeout(() => {
                    this.iswarntoast = false
                }, 1500)
        }
    }
}
</script>

<style lang="less" scoped>
.home_container {
    background: #eee;
    height: auto;
}
.flex-row {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
}
.flex-column {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    align-items: stretch;
}
.row-s:after, .row-s:before {
    clear: both;
    overflow: hidden;
    height: 0;
    display: block;
    content: '';
}
// detail_info
.detail_info {
    padding-bottom: 1px;
    position: relative;
    background: #fff;
}
.detail_info_top {
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    z-index: 1;
}
.detail_swiper {
    width: 100%;
    height: 375px;
}
.swiper-slide img {
    width: 100%;
    height: 100%;
}
.detail_num {
    height: 45px;
    display: block;
    overflow: hidden;
    position: relative;
    font-family: Arial, Helvetica, sans-serif;
}
.detail_price {
    position: relative;
    float: left;
    font-size: 14px;
    margin-left: 12px;
    margin-top: 5px;
}
.detail_price i {
    font-size: 16px;
    color: #F91415;
}
.detail_price font {
    font-size: 27px;
}
.old_price {
    padding-left: 16px;
    text-decoration: line-through;
    color: #888;
    font-size: 14px;
}
.old_price_money {
    text-decoration: line-through;
    color: #888;
    font-size: 14px;
}
.detail_sellnum {
    position: absolute;
    right: 10px;
    top: 16px;
    height: 45px;
}
.detail_sellnum span {
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    margin: 3px 0 2px 0;
}
.red {
    color: #F91415;
}
.gray {
    color: #888;
}
.detail_title {
    box-sizing: border-box;
    padding: 0 10px;
    font-size: 15px;
    height: 40px;
    color: #333;
    line-height: 20px;
    overflow: hidden;
    background: #fff;
    width: 88%;
    max-height: 650px;
    margin-top: 2px;
    font-family: Arial, Helvetica, sans-serif;
}
.detail_title img {
    border-radius: 3px;
    vertical-align: middle;
    margin-bottom: 1px;
    width: 16px;
    height: 16px;
    margin-right: 3px;
}
.favourite {
    display: block;
    width: 40px;
    height: auto !important;
    background: url(../assets/favourite.png) no-repeat center top/20px;
    font-size: 12px;
    color: #666;
    text-align: center;
    position: absolute;
    right: 10px;
    padding-top: 20px;
    bottom: 2px;
}
.collect {
    display: block;
    width: 40px;
    height: auto !important;
    background: url(../assets/collect.png) no-repeat center top/20px;
    font-size: 12px;
    color: #666;
    text-align: center;
    position: absolute;
    right: 10px;
    padding-top: 20px;
    bottom: 2px;
}
// award
.award {
    background: #fff;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
}
.award_num {
    padding-left: 10px;
    color: #f18202;
}
.award_get {
    color: #38a8f1;
    margin-left: 8px;
    border-bottom: 1px dashed #666;
}
// coupon_container
.d_coupon_container {
    margin-bottom: 8px;
    border-radius: 0 0 10px 10px;
    box-sizing: border-box;
    padding: 5px 10px 12px 10px;
    background: #fff;
}
.d_coupon {
    background: linear-gradient(270deg,#f35a76 0,#ff5f5b 100%);
    height: 65px;
    border-radius: 10px;
    box-sizing: border-box;
}
.coupon_left {
    flex: 1;
    height: 35px;
    padding: 15px 0;
}
.coupon_num {
    color: #fff;
    height: 35px;
    line-height: 35px;
    padding: 0 20px;
    font-size: 15px;
    font-weight: 400;
    align-items: baseline;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}
.coupon_date span {
    color: #fff;
    height: 18px;
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}
.coupon_right {
    height: auto;
    color: #fff;
    border-left: 1px dashed #fff;
    padding: 15px 0;
    flex: 0 0 120px;
    text-align: center;
    font-size: 14px;
    position: relative;
}
// recommend_container
.recommend_container {
    margin-bottom: 7px;
    border-radius: 10px;
    background: #fff;
    box-sizing: border-box;
    padding: 7px 12px 12px 12px;
}
.recommend_title {
    align-items: flex-end;
    font-family: Arial, Helvetica, sans-serif;
    height: 27px;
}
.recommend_content {
    background: #f4f4f4;
    border-radius: 10px;
    padding: 8px 18px;
    margin-top: 10px;
    position: relative;
    color: #555;
    line-height: 18px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
}
.recommend_icon {
    font-size: 13px;
    color: #fe3738;
    line-height: 20px;
}
.recommend_icon img {
    width: 18px;
    height: 18px;
    margin-right: 4px;
}
// shop_container
.shop_container {
    width: 100%;
    background: #fff;
}
.shop_title {
    padding: 5px 0;
    min-height: 60px;
    background: #fff;
    margin: 0 20px;
}
.shop_title img {
    float: left;
    width: 60px;
    height: 60px;
    border-radius: 3px;
}
.shop_info {
    position: relative;
    z-index: 1;
    padding-left: 80px;
    min-height: 60px;
    font-family: Arial, Helvetica, sans-serif;
    zoom: 1;
}
.title {
    overflow: hidden;
    margin-right: 115px;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 400;
    font-size: 14px;
    line-height: 35px;
}
.platform img {
    height: auto;
}
.enter_shop {
    position: absolute;
    top: 50%;
    right: 0;
    z-index: 1;
    margin-top: -15px;
    color: #888;
    font-size: 14px;
    line-height: 35px;
    zoom: 1;
}
.enter_shop img {
    height: 35px;
    width: 35px;
    float: right;
    vertical-align: middle;
}
.shop_tab {
    text-align: center;
    line-height: 50px;
    border: 1px solid #f5f5f5;
    color: #555;
    font-size: 14px;
}
.tab {
    width: 33.3333333%;
    color: #555;
    float: left;
    font-size: 14px;
}
// evaluate_container
.evaluate_container {
    width: 100%;
    background: #fff;
    margin-top: 1px;
}
.evaluate_key {
    padding: 10px;
    text-align: center;
}
.evaluate_key div {
    clear: both;
    display: inline-block;
    margin-right: 4px;
    margin-bottom: 6px;
    padding: 5px 8px;
    font-size: 11px;
    border-radius: 16px;
    background: #fff2f2;
    color: #333;
    font-family: Arial, Helvetica, sans-serif;
}
// goods_detail
.goods_detail {
    display: block;
    margin-top: 0px;
    margin-bottom: 0px;
    background: #fff;
    width: 100%;
}
.show_detail {
    width: 100%;
    box-sizing: border-box;
    padding: 0 10px;
    font-size: 16px;
}
.limit_height {
    height: auto;
    max-height: 888px;
    overflow: hidden;
}
.all_height {
    overflow: hidden;
}
.detail_list {
    border-top: 0;
    padding: 0 10px;
}
.detail_list img {
    width: 100%;
    max-width: 100%;
    margin-top: 0;
    display: block;
}
// change_height
.change_height {
    background: #fff;
    text-align: center;
    padding: 20px;
}
.change_button {
    background: linear-gradient(270deg,#fee 0,#ffe2e8 100%);
    color: #ff313e;
    padding: 10px;
    border-radius: 25px;
    font-size: 14px;
    font-family: Arial, Helvetica, sans-serif;
}
.change_button img {
    height: 16px;
}
// guess_like
.guess_like {
    background: #fff;
    width: 100%;
    height: 100%;
    margin-top: 15px;
    clear: both;
    padding-bottom: 3%;
    border-top: #e5e5e5 solid 1px;
    border-bottom: #e5e5e5 solid 1px;
}
.guess_title {
    display: inline-block;
    text-align: center;
    height: 40px;
    color: #444;
    line-height: 40px;
    font-size: 14px;
    width: 100%;
    box-sizing: border-box;
    padding: 0 10px;
    border-bottom: 1px solid #f0f0f0;
    font-family: Arial, Helvetica, sans-serif;
}
.guess_refresh {
    padding: 0 0 0 5px;
    position: absolute;
}
.guess_refresh img {
    width: 15px;
}
.guess_list {
    height: auto;
}
.guess_list ul {
    width: 100%;
}
.guess_list li {
    float: left;
    width: 47%;
    position: relative;
    margin: 2% 0 0 2%;
    background: #fff;
}
.guess_list img {
    height: 176px;
    border-radius: 5px;
    width: 100%;
    position: relative;
    display: inherit;
}
.guess_list h3 {
    width: 100%;
    font-size: 12px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 300;
    color: #808080;
    height: 26px;
    line-height: 26px;
    background: rgba(255, 255, 255, 0.8);
    overflow: hidden;
    text-indent: 5px;
    padding: 3% 0 2% 0;
}
.guess_price {
    width: 96%;
    height: 26px;
    line-height: 18px;
    position: relative;
    bottom: 0;
    margin: 0 2%;
    font-family: Arial, Helvetica, sans-serif;
}
.price_left {
    font-size: 14px;
    color: #fe4e4e;
}
.price_left i {
    font-style: normal;
    font-size: 12px;
}
.price_right {
    color: #999;
    display: block;
    position: absolute;
    height: 16px;
    line-height: 12px;
    font-size: 13px;
    text-align: right;
    top: 5px;
    right: 2px;
}
.guess_coupon {
    width: 60px;
    height: 30px;
    position: absolute;
    right: 0px;
    top: 0px;
    border-radius: 1px;
    text-align: center;
}
.guess_coupon span {
    border-radius: 10px 0 0 10px;
    margin-top: 5px;
    background: rgba(255, 34, 73, 0.77);
    width: 60px;
    height: 20px;
    font-size: 12px;
    display: block;
    color: #fff;
    overflow: hidden;
    line-height: 20px;
    font-family: Arial, Helvetica, sans-serif;
}
// go_back
.go_back {
    position: fixed;
    top: 15px;
    z-index: 999;
    width: 100%;
    max-width: 46.875px;
    margin: 0 auto;
    left: 0;
}
.go_back img {
    width: 35px;
    float: left;
    margin-left: 15px;
}
// return_home
.return_home {
    position: fixed;
    top: 15px;
    z-index: 999;
    width: 100%;
    max-width: 46.875px;
    margin: 0 auto;
    right: 0;
}
.return_home img, .make_pic img, .find_same img {
    width: 35px;
    float: right;
    margin-right: 15px;
}
// make_pic
.make_pic {
    position: fixed;
    top: 65px;
    z-index: 999;
    width: 100%;
    max-width: 46.875px;
    margin: 0 auto;
    right: 0;
}
// find_same
.find_same {
    position: fixed;
    top: 115px;
    z-index: 999;
    width: 100%;
    max-width: 46.875px;
    margin: 0 auto;
    right: 0;
}
// bottom_menu
.bottom_menu {
    position: fixed;
    width: 100%;
    max-width: 640px;
    height: 50px;
    line-height: 50px;
    display: -webkit-box;
    z-index: 999;
    background: #fff;
    color: #fff;
    font-size: 12px;
    margin: auto;
    left: 0;
    right: 0;
    bottom: 0;
    font-family: Arial, Helvetica, sans-serif;
}
.show_home {
    top: 0;
    left: 0;
    color: #666;
    text-align: center;
    position: absolute;
    z-index: 999;
    width: 20%;
    float: left;
}
.show_home img, .show_share img {
    height: 16px;
}
.show_home a, .show_share a {
    color: #666;
}
.show_share {
    top: 0;
    left: 18%;
    color: #666;
    text-align: center;
    position: absolute;
    z-index: 999;
    width: 20%;
    float: left;
}
.show_open {
    top: 7px;
    left: 68%;
    background: linear-gradient(270deg,#fee 0,#ffe2e8 100%);
    text-align: center;
    border-radius: 0px 25px 25px 0px;
    height: 35px;
    line-height: 35px;
    position: absolute;
    z-index: 999;
    width: 30%;
    float: left;
}
.show_open a {
    color: #ff313e;
}
.show_get {
    color: #ffffff;
    font-size: 12px;
    font-weight: normal;
    letter-spacing: 0px;
    -webkit-box-flex: 1;
    text-align: center;
    width: 100%;
    max-width: 750px;
}
.get_button {
    top: 7px;
    left: 38%;
    background: linear-gradient(to left,#f5635e 0,#ea4e6d 100%);
    text-align: center;
    border-radius: 25px 0 0 25px;
    height: 35px;
    line-height: 35px;
    position: absolute;
    z-index: 999;
    width: 30%;
    float: left;
}
// loading_container
.loading_container {
    width: 100%;
    height: 100px;
    -webkit-box-pack: center;
    -webkit-box-align: center;
    position: fixed;
    top: 50%;
    left: 0;
    right: 0;
    margin-top: -50px;
    z-index: 1000;
    padding: 5px;
    text-align: center;
}
.loading {
    width: 120px;
    height: 80px;
    opacity: 0.6;
    font-size: 12px;
    color: #fff;
    background: #000;
    text-align: center;
    border-radius: 5px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-pack: center;
    -webkit-box-align: center;
    margin: 0 auto;
}
.loading img {
    width: 45px;
    height: 45px;
}
// cover
.cover {
    z-index: 1000;
    background-color: rgb(0, 0, 0);
    opacity: 0.8;
    width: 100%;
    max-width: 640px;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: 0;
    height: 100%;
    position: fixed;
    pointer-events: auto;
}
// show_pic
.show_pic {
    z-index: 1000;
    top: 5%;
    left: 0;
    position: fixed;
    right: 0;
    width: 340px;
    max-width: 90%;
    margin: auto;
    border-radius: 5px;
    padding: 0;
    background-color: #fff;
    box-shadow: 1px 1px 50px rgba(0,0,0,.3);
    animation-fill-mode: both;
    animation-duration: .3s;
    pointer-events: auto;
}
.pic {
    position: relative;
    overflow: visible im !important;
}
.pic_outside {
    padding: 10px;
    width: 100%;
}
.pic_inside {
    width: 100%;
}
.pic_inside img {
    width: 100%;
    max-height: 530px;
    width: 100%;
}
.pic_close {
    position: absolute;
    right: 15px;
    top: 15px;
    font-size: 0;
    line-height: initial;
}
.close_btn {
    position: absolute;
    right: -28px;
    top: -28px;
    width: 30px;
    height: 30px;
    margin-left: 0;
    font-size: 12px;
    display: inline-block;
    vertical-align: top;
}
.close_icon {
    background: url(../assets/icon.png) no-repeat;
    background-position: -149px -31px;
}
.pic_btn {
    padding-top: 10px;
    text-align: right;
    padding: 0 10px 12px;
    pointer-events: auto;
}
.pic_btn div {
    height: 28px;
    line-height: 28px;
    margin: 0 6px;
    padding: 0 15px;
    border: 1px solid #dedede;
    border-radius: 2px;
    font-weight: 400;
    cursor: pointer;
    text-decoration: none;
    display: inline-block;
    vertical-align: top;
}
.pic_tip {
    width: 100%;
    text-align: center;
    font-size: 13px;
    color: #888;
}
.copy_btn {
    border-color: rgb(72, 152, 213);
    background-color: rgb(46, 141, 237);
    color: #fff;
}
// back_top
.back_top {
    width: 42px;
    height: 42px;
    position: fixed;
    right: 20px;
    z-index: 999;
    background-image: url(../assets/go_top.png);
    background-size: contain;
    -webkit-transition: bottom .8s ease, opacity .6s ease;
    opacity: 0;
}
.back_top span {
    position: absolute;
    bottom: 9px;
    width: 100%;
    display: block;
    height: 12px;
    line-height: 12px;
    text-align: center;
    font-size: 12px;
    color: #333;
}
.hide_back_top {
    bottom: -94px;
    opacity: 0;
}
.show_back_top {
    bottom: 94px;
    opacity: 1;
}
// qc_container
.qc_container {
    top: 5%;
    margin: 0 auto;
    left: 0;
    right: 0;
    height: 450px;
    width: 320px;
    max-width: 90%;
    position: fixed;
    z-index: 9999999;
    background: #fff;
}
.qc_img {
    width: 100%;
}
.qc_info {
    font-family: Arial, Helvetica, sans-serif;
    width: 100%;
    font-size: 13px;
    padding: 0 10px;
}
.qc_left {
    width: 70%;
}
.qc_title {
    height: 40px;
    line-height: 20px;
    overflow: hidden;
    box-sizing: border-box;
}
.qc_right {
    width: 65px;
    height: auto;
    position: absolute;
    right: 15px;
    top: 325px;
}
.qc_price {
    height: 45px;
    position: relative;
    font-family: Arial, Helvetica, sans-serif;
    float: left;
}
.qc_price i {
    font-size: 16px;
    color: #F91415;
}
.qc_price font {
    font-size: 25px;
}
.qc_old {
    padding-left: 5px;
    text-decoration: line-through;
    color: #888;
    font-size: 14px;
}
.qc_sell {
    position: absolute;
    left: 10px;
    height: 20px;
    bottom: 10px;
}
.qc_tip {
    text-align: center;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 12px;
    margin-top: 10px;
    margin-left: 5px;
    background: linear-gradient(270deg, #f35a76 0, #ff5f5b 100%);
    color: #fff;
    border-radius: 5px;
}
.qc {
    width: 100%;
    height: 100%;
}
.icon-box-info p {
    font-family: Arial, Helvetica, sans-serif;
}
</style>
